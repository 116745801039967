import React, { Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "../components/GSRoutes/PrivateRoute";


const LinkQueryManager = lazy(() => import("./QueryManager/LinkQueryManager"));
const Etl = lazy(() => import("./ETLView/etlview"));
const GSLogin = lazy(() => import("../components/GSHelperComponents/GSLogin"));
const LinkManager = lazy(() => import("./LinkManager/LinkManager"));
const ReportView = lazy(() => import("./ReportingView/ReportView"));
const StartingPage = lazy(() => import("./StartingPage/StartingPage"));
const LearnPage = lazy(() => import("./LearnPage/LeanPage"));
const HelpPage = lazy(() => import("./HelpView/HelpPage"));
const Register = lazy(() => import("./Register/Register"));
const RegisterApp = lazy(() => import("./Register/RegisterApp"));
const MissionControl = lazy(() => import("./MissionControl/MissionControl"));
const UserSpace = lazy(() => import("./UserSite/UserSpace"));
const FileManager = lazy(() => import("./FileManager/filemanager"));
const JobView = lazy(() => import("./JobView/JobView"));
const ThorView = lazy(() => import("./ThorView/ThorView"));
const CVAView = lazy(() => import("./CVAManager/CVAView"));
const RXAView = lazy(() => import("./RXA/RXA"));
const MonitorView = lazy(() => import("./Monitor/MonitorDash"));
const RXAAPPView = lazy(() => import("./RXA/RXAApp"));
const CameraViewApp = lazy(() => import("./RXA/CameraViewApp"));
const CameraView = lazy(() => import("./RXA/CameraView"));
const RTCStreamer = lazy(() => import("../components/rxa/RTCStreamer"));
const StreamerSetupNew = lazy(() => import("./Streamer/StreamerNew"));
const Checkout = lazy(() => import("./Register/Checkout"));

const Start = lazy(() => import("./Start/Start"));

export const websiteRoutes = (setLoggedIn, setError, newMessage) => {

    var isflutter = typeof window.flutter_inappwebview !== 'undefined'
    // var isflutter = true

    return (
        <Suspense fallback={<div />}>
            <Switch>
                <Route path="/login" render={(props) => {
                    return <GSLogin />
                }} />
                <PrivateRoute path="/data" content={<LinkQueryManager />} />
                <PrivateRoute path="/etl" content={<Etl />} />
                <PrivateRoute path="/reports" content={<ReportView />} />
                <PrivateRoute path="/linkmanager" content={<LinkManager />} />
                <PrivateRoute path="/user" content={<UserSpace />} />
                <PrivateRoute path="/controlcenter" content={<MissionControl newMessage={newMessage} />} />
                <PrivateRoute path="/files" content={<FileManager />} />
                <PrivateRoute path="/jobs" content={<JobView />} />
                <PrivateRoute path="/thor" content={<ThorView />} />
                <PrivateRoute path="/cxa" content={<CVAView />} />
                <PrivateRoute path="/rxa/camera/:name" content={<RTCStreamer />} />
                <PrivateRoute path="/rxa/:name/:view" content={<CameraView />} />
                <PrivateRoute path="/rxa" content={<RXAView />} />
                <PrivateRoute path="/monitor" content={<MonitorView />} />
                <PrivateRoute path="/streamer" content={<StreamerSetupNew />} />
                <PrivateRoute path="/start" content={<Start />} />
                <PrivateRoute path="/help" content={<HelpPage />} />
                <Route path="/learn" exact render={(props) => { return <LearnPage /> }} />
                {isflutter ?
                    <Route path="/register" exact render={(props) => { return <RegisterApp /> }} /> :
                    <Route path="/register" exact render={(props) => { return <Register /> }} />
                }
                <Route path="/checkout" exact render={(props) => { return <Register /> }} />
                <Route path="/registerAIconfirm" render={(props) => { return <Register /> }} />
                <Route path="/" exact render={(props) => { return <GSLogin /> }} />
            </Switch>
        </Suspense>
    )
}

export const APIURL = () => {

    let prodai = "https://glass-sphere-ai.de/frontend"
    // 
    // let prodai = "http://localhost:8091/frontend"
    // let prodai = "http://localhost:8091/frontend"
    // let prodai = "http://10.0.2.2:8091/frontend"


    // var ldservice = "http://localhost:8202"
    // var queryservice = "http://localhost:8080"
    // var storageservice = "http://localhost:8085"
    // var missioncontrol = "http://localhost:8081"
    // var streamingservice = "http://localhost:8083"
    // var pythonservice = "http://localhost:5000";
    // var streamingserver = "http://localhost:8000"
    // var signalingserver = "http://localhost:4000"

    //---------------------------------------------
    // var ensserviceURL = "http://localhost:8087"
    // var ensserviceURL = "http://10.0.2.2:8087"
    var ensservice = prodai
    // --------------------------------------------

    var ldservice = prodai
    var queryservice = prodai
    var storageservice = prodai
    var streamingservice = prodai
    var pythonservice = prodai
    var missioncontrol = "https://glass-sphere-ai.de"
    var signalingserver = "https://glass-sphere-ai.de"
    var streamingserver = "https://streaming.ens-fiti.de"
    // --------------------------------------------
    var ensserviceURL = "https://ens-fiti.de"
    // --------------------------------------------

    return {
        readAllLinksForClass: ldservice + "/linkeddataanalyzer/links/",
        readDataLinksForClass: ldservice + "/linkeddataanalyzer/dtabledatalinks/",
        readERPDataLinksForClass: ldservice + "/linkeddataanalyzer/erpdatalinks/",
        relations: ldservice + "/linkeddataanalyzer/relations",
        readBuildInfo: ldservice + "/linkeddataanalyzer/buildinfo/",
        readLevel1dTables: ldservice + "/linkeddataanalyzer/erplevel1",
        readLevel2dTables: ldservice + "/linkeddataanalyzer/level2",
        readRangeForDataLink: ldservice + "/linkeddataanalyzer/datalinks/range/",
        readForeignLinkNamesFordTable: ldservice + "/linkeddataanalyzer/foreignlinks/names/",
        readExtendedPropertiesAndRanges: ldservice + "/linkeddataanalyzer/propertyrange/",
        graphqlQuery: queryservice + "/gslmaa",
        // graphqlQuery: queryservice + "/gslmaa",
        graphqlQuerylmaa: queryservice + "/gslmaa",

        register: ensservice + "/registerai",
        confirmLogin: ensservice,

        // confirmRegister: ensservice + "/registerconfirm/",
        oauthCred: ensservice + "/user/oauth",
        // oauthCredDetails: ensservice + "/user/oauth/details",
        deleteOauthCredDetails: ensservice + "/user/oauth/",

        userInfo: ensservice + "/user/info",

        forgetPassword: ensserviceURL + "/forgetPasswordAI",
        databases: ensservice + "/user/database",
        // getSharedDatabases: ensservice + "/user/shareddatabase",
        getActiveDatabase: ensservice + "/user/database/active",
        getCNodes: ensservice + "/user/cnodes",
        deleteCNodes: ensservice + "/user/cnode/",

        shareDatabase: ensservice + "/user/share/database",
        deleteSharedUser: ensservice + "/user/share/database/delete",

        ensservice: ensservice,
        script: pythonservice + "/script",

        getFiles: storageservice + "/storage/files",
        getURLForFile: storageservice + "/videos/file/",
        deleteVodFiles: storageservice + "/videos/file/",
        getFile: storageservice + "/storage/file",
        getFolder: storageservice + "/storage/folder",
        getFolderContent: storageservice + "/storage/folder/",
        getThumbNail: storageservice + "/storage/thumbnail/",
        vodLimitUsage: storageservice + "/storage/vod/usage",
        getVODFolder: storageservice + "/storage/vod/folder",

        websocket: missioncontrol + "/gs-guide-websocket?gssession=",
        restartCNodeChannelPipeline: prodai + "/missioncontrol/channelpipelines/rva/restart",
        checkPipelineConsistency: prodai + "/missioncontrol/channelpipelines/rva/check",
        triggerGlobalPipeline: prodai + "/missioncontrol/pipeline/",

        pipeline: prodai + "/missioncontrol/pipeline",
        streamkey: streamingservice + "/streaming/keys",

        toggleVod: streamingservice + "/streaming/vodEnable",
        streamInfo: streamingservice + "/streaming/live",
        streamkeyDelete: streamingservice + "/streaming/keys/delete",
        streams: streamingserver + "/live/",
        viewVod: storageservice + "/videos/vod.m3u8",
        login: prodai + "/auth/login",
        ping: prodai + "/ping",
        signalingServer: signalingserver,
        turnserver: prodai + "/user/turn"

    }
}
